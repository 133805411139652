.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border: 1px solid #ced4da
}

.ant-select-selection-placeholder,
.ant-select-tree .ant-select-tree-treenode-disabled .ant-select-tree-node-content-wrapper {
  color: #000;
}

.ant-select-arrow {
  color: #000;
}