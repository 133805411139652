.sidebar-header {
  padding: 15px 12px;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 1.5rem;
  color: #ef8412;
  letter-spacing: 1px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: center;
}

.user-panel {
  padding: 0 24px 24px 24px;
  .info {
    font-weight: bold;
  }
  .logout {
    text-decoration: none;
    color: rgba(0, 0, 0, 0.7);
  }
}

/* The navigation bar */
.navbar {
  overflow: hidden;
  background-color: #ffffff;
  position: fixed; /* Set the navbar to fixed position */
  top: 0; /* Position the navbar at the top of the page */
  width: 100%; /* Full width */
  box-shadow: 3px 0 5px 1px #b9af9d;
  justify-content: flex-end;
  z-index: 999;
  height: 68px;
  padding-left: 300px;
  .user {
    float: left;
    display: block;
    color: rgba(0, 0, 0, 0.7);
    text-align: center;
    padding: 14px 16px;
    text-decoration: none;
    font-weight:bold;
  }
  /* Change background on mouse-over */
  .logout:hover {
    background: #ddd;
    color: black;
    cursor: pointer;
  }
  .title {
    padding-right: 10px;
    font-weight: bold;
    letter-spacing: 2pt;
    font-size: 1rem;
  }
}

.pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item {
  padding-left: 12px;
}

.pro-sidebar .pro-menu .pro-menu-item {
  font-size: 14px;
}

.pro-sidebar .pro-menu .pro-menu-item.pro-sub-menu .pro-inner-list-item .pro-inner-item {
  padding-left: 56px;
  padding-top: 12px;
  padding-bottom: 12px;
}

.pro-sidebar .pro-menu .pro-menu-item.pro-sub-menu .pro-inner-list-item {
  padding-left: 0;
}

.pro-sidebar .pro-menu > ul > .pro-sub-menu > .pro-inner-list-item > div > ul {
  padding-top: 0;
  padding-bottom: 0;
}