.custom-row {
    width: 80%;
    margin: auto;
    margin-bottom: 20px;
}
.category-list {
    max-width: 80%;
    margin: 20px auto;
}
.carousel-content > .active {
    color: #ffffff;
    background-color: #e58d46;
}
.pt-20 {
    padding-top: 20px !important;
}
.tbl-wrapper {
    max-height: 500px;
    overflow: auto;
}
.limit-quantity-warning-modal {
    width: 350px;
}