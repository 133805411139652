.formInline {
  padding: 20px 0;
  
  button {
      margin-top: 30px;
      width: 150px;
  }
}
.text-center {
  text-align: center;
}

.button-remove {
  color: red !important;
  background-color: 'rgb(243, 243, 243)';
  width: 40px;
  height: 40px;
}
.button-accept {
  color: rgb(76, 175, 80) !important;
  width: 40px;
  height: 40px;
}

.intput-text {
  border:none !important;
  height: 33px !important;
  outline: 0 !important;
  border-width: 0 0 2px;
  border-bottom: 1px solid #000 !important;
  border-radius: 0px !important;
  padding-left: 0px !important;
}

.btn-primary {
  color: #fff;
  border-color: #ef8412 !important;
}
.btn-primary:focus {
  box-shadow: none;
}
.btn-add {
  margin-bottom: 10px;
  min-width: 150px;
}
.btn-action {
  margin: 5px;
}
.btn-cancel {
  width: 100px;
  margin: 10px;
}
.btn-submit {
  width: 100px;
}
.alert {
  position: relative;
  padding: 0.5rem 1rem !important;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}

.alert-dismissible .btn-close {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 2;
    padding: 0.8rem 1rem !important;
}

.modal-add {
  .invalid-feedback{
    display: inline;
  }
}

.card {
  position: inherit;
}

/******************************
REACT GRID TABLE
******************************/
.rgt-cell-inner.darkgray {
  background-color: #efefef;
  margin: 0px !important;
  display: flex !important;
  height: 100%;
  padding: 0 0.5rem;
  align-items: center;
}

.rgt-cell-header {
  background-color: #F3EBE8 !important;
  border-right: #dee2e6;
  min-height: 40px !important;
  max-height: 40px !important;
  font-weight: bold;
  border-bottom: 2px solid #000 !important;
}

.rgt-cell-header-inner {
  background-color: #F3EBE8 !important;
  padding: 0 0.5rem !important;
  justify-content: center;
}

.rgt-cell-header-inner-not-pinned-right {
  border-right: 1px solid #dee2e6 !important;
}

.rgt-cell-inner {
  margin: 0 0.5rem !important;
}

.rgt-cell {
  border-right: 1px solid #dee2e6 !important;
  border-bottom: 1px solid #dee2e6 !important;
}


