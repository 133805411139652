.carousel-container {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.carousel-wrapper {
  display: flex;
  width: 100%;
  position: relative;
  border: solid #dee2e6 1px;
  background-color: rgba(255, 208, 127, 0.3);
}

.carousel-content-wrapper {
  overflow: hidden;
  width: 100%;
  height: 100%;
}

.carousel-content {
  display: flex;
  transition: all 250ms linear;
  -ms-overflow-style: none; /* hide scrollbar in IE and Edge */
  scrollbar-width: none; /* hide scrollbar in Firefox */
}

/* hide scrollbar in webkit browser */
.carousel-content::-webkit-scrollbar,
.carousel-content::-webkit-scrollbar {
  display: none;
}

.carousel-content > * {
  width: 100%;
  flex-shrink: 0;
  flex-grow: 1;
  text-align: center;
}

.carousel-content.show-2 > * {
  width: 50%;
}

.carousel-content.show-3 > * {
  width: calc(100% / 3);
}

.carousel-content.show-4 > * {
  width: calc(100% / 4);
}

.left-arrow,
.right-arrow {
  position: absolute;
  z-index: 1;
  top: 50%;
  transform: translateY(-50%);
  width: 40px;
  height: 40px;
  background-color: #f1f1f1;
  border: 1px solid #ccc;
  font-size: 22px;
}
.left-arrow:hover,
.right-arrow:hover {
    background-color: #bbb;
}
.left-arrow {
  left: -40px;
}

.right-arrow {
  right: -40px;
}

@media (hover: none) and (pointer: coarse) {
  .left-arrow,
  .right-arrow {
    display: none;
  }
}
.carousel-item-content {
  padding: 8px;
  border-right: solid #dee2e6 1px;
}
.carousel-item-content:not(.active):hover {
  color: rgb(201, 109, 12);
  cursor: pointer;
}
