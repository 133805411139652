.custom-row {
    width: 80%;
    margin: auto;
    margin-bottom: 20px;
}
.category-list {
    max-width: 80%;
    margin: 20px auto;
}
.carousel-content > .active {
    color: #ffffff;
    background-color: #e58d46;
}
.pt-20 {
    padding-top: 20px !important;
}
.list-body .react-datepicker {
    z-index: 20 !important;
    position: absolute !important;
    margin-left: -121px !important;
}