.formSearch {
  padding: 0px 28px 68px 50px;
  .button-search {
      margin-top: 30px;
      width: 150px;
  }

  input[type="text"] {
    height: 38px;
    width: 100%;
    border: none;
    border-radius: 3px;
    border: 1px solid #e0e3e9;
    padding: 0 10px;
    padding-left: 30px;
    outline: 0;
    font-size: 13px
  }
}
.text-center {
  text-align: center;
}
.rgt-cell-deleteflg {
  justify-content: center;
}

.button-remove {
  color: red !important;
  background-color: 'rgb(243, 243, 243)';
  width: 40px;
  height: 40px;
}
.button-accept {
  color: rgb(76, 175, 80) !important;
  width: 40px;
  height: 40px;
}

.intput-text {
  border:none !important;
  height: 33px !important;
  outline: 0 !important;
  border-width: 0 0 2px;
  border-bottom: 1px solid #000 !important;
  border-radius: 0px !important;
  padding-left: 0px !important;
}

.darkgray {
  background-color: darkgray;
}
.rgt-cell {
  padding: 10px;
}
.btn-primary {
  color: #fff;
  border-color: #ef8412 !important;
}
.btn-primary:focus {
  box-shadow: none;
}
.btn-action {
  margin: 5px;
}
.btn-cancel {
  width: 100px;
  margin: 10px;
}
.btn-submit {
  width: 100px;
}
.alert {
  position: relative;
  padding: 0.5rem 1rem !important;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}

.alert-dismissible .btn-close {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 2;
    padding: 0.8rem 1rem !important;
}
.modal-add{
  .invalid-feedback{
    display: inline;
  }
}
.rgt-cell-header {
  background-color: #ef8412 !important;
}
.rgt-cell-header-inner {
  background-color: #ef8412 !important;
}
.card {
  position: inherit;
}
.check-center {
  padding-left: 10px;
}

input[type="file"] {
  display: none;
}
.custom-file-upload {
  border: 1px solid #ccc;
  display: inline-block;
  padding: 7px;
  cursor: pointer;
  background-color: #ef8412 !important;
  color:#fff;
  border-radius: 0.25rem;
}
.csv-export {
  width: 165px !important;
  padding: 6px;
}

.btn-space {
  margin-right: 5px;
}

.datepicker-custom {
  .invalid-feedback {
    display: inline;
  }
}
.text-right {
  text-align: right;
  justify-content: center;
}
.padding-bottom-row {
  padding-bottom:10px;
}
.order-type-maintenance-master{
  .rgt-wrapper{
    border: none !important;
  }
}
.react-datepicker-popper {
  z-index: 3 !important;
}


$breakpoint-1200: 1200px;
@media (min-width: $breakpoint-1200) {
  .rgt-cell-virtual{
    width: 0px !important;
  }
  .rgt-cell-header-virtual-col{
    z-index: 1 !important;
  }
  .rgt-cell-header-order_type , .rgt-cell-order_type{
    width: 105% !important;
    z-index: 2 !important;
  }
}

$breakpoint-1800: 1800px;
@media (min-width: $breakpoint-1800) {
  .rgt-cell-virtual{
    width: 0px !important;
  }
  .rgt-cell-header-virtual-col{
    width: 0px !important;
    z-index: 1 !important;
  }

  .rgt-cell-header-order_type , .rgt-cell-order_type{
    width: 160% !important;
    z-index: 2 !important;
  }
}

$breakpoint-2100: 2100px;
@media (min-width: $breakpoint-2100) {
  .rgt-cell-virtual{
    width: 0px !important;
  }
  .rgt-cell-header-virtual-col{
    width: 0px !important;
    z-index: 1 !important;
  }

  .rgt-cell-header-order_type , .rgt-cell-order_type{
    width: 230% !important;
    z-index: 2 !important;
  }
}