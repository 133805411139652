@import url("https://fonts.googleapis.com/css?family=Roboto&display=swap");

$sidebar-bg-color: #ffffff;
$submenu-bg-color: rgba(123, 1, 0, 0.05);
$submenu-bg-color-collapsed: rgba(123, 1, 0, 0.95);
$sidebar-color: rgba(#000000, 0.7);
$highlight-color: rgb(201, 109, 12);
$icon-bg-color: rgba(123, 1, 0, 0.05);
// $header-dg-color: 
$icon-size: 35px;

@import "~react-pro-sidebar/dist/scss/styles.scss";
body {
  margin: 0;
  height: 100vh;
  color: #353535;
  font-family: "Roboto", sans-serif;
}

#root {
  height: 100%;
}

.pro-sidebar {
  box-shadow: 3px 0 5px 1px #b9af9d;
}
.app {
  height: 100%;
  display: flex;
  position: relative;

  .btn-toggle {
    cursor: pointer;
    width: 35px;
    height: 35px;
    background: #353535;
    color: #fff;
    text-align: center;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 15px;
    display: none;
  }

  .sidebar-btn-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    .sidebar-btn {
      transition: width 0.3s;
      width: 150px;
      padding: 1px 15px;
      border-radius: 40px;
      background: rgba(255, 255, 255, 0.05);
      color: #adadad;
      text-decoration: none;
      margin: 0 auto;
      height: 35px;
      display: flex;
      align-items: center;
      justify-content: center;
      text-overflow: ellipsis;
      overflow: hidden;
      span {
        margin-left: 5px;
        font-size: 13px;
      }
      &:hover {
        color: $highlight-color;
      }
    }
  }
  .pro-inner-item {
    &:hover {
      background-color: rgba($color: #db7910, $alpha: 0.3);
    }
  }
  .pro-inner-item .active {
    color: #e77700;
  }

  .collapsed {
    .sidebar-btn {
      display: inline-block;
      line-height: 40px;
      padding: 0;
    }
  }

  main {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    background-color: #eee;
    padding: 86px 1.5rem 3rem;

    header {
      margin-bottom: 40px;
      border-bottom: 1px solid #efefef;
      h1 {
        display: flex;
        align-items: center;
        transform: translateX(-20px);
      }
    }

    footer {
      margin-top: auto;
      color: #888;
      text-align: center;
      a {
        text-decoration: none;
        color: #888;
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
  .social-bagdes {
    margin-top: 10px;
    img {
      margin: 5px;
    }
  }

  .block {
    display: flex;
    margin-bottom: 24px;
    font-size: 14px;
    color: #545454;
    > span {
      margin-left: 10px;
    }
  }

  &.rtl {
    direction: rtl;
    text-align: right;

    header {
      h1 {
        transform: translateX(20px);
      }
    }
    .block {
      direction: rtl;
      > span {
        margin-left: 0;
        margin-right: 10px;
      }
    }
    .sidebar-btn-wrapper {
      .sidebar-btn {
        span {
          margin-left: 0;
          margin-right: 5px;
        }
      }
    }
  }

  @media (max-width: 767.98px) {
    overflow-x: hidden;
    header {
      h1 {
        font-size: 24px;
      }
    }
  }
  @media (max-width: $breakpoint-md) {
    .btn-toggle {
      display: flex;
    }

    &.rtl {
      .btn-toggle {
        margin-left: auto;
      }
    }
  }
}

.badge {
  padding: 3px 6px;
  font-size: 9px;
  letter-spacing: 1px;
  border-radius: 15px;
  &.red {
    color: #ffffff;
    background: #d63030;
  }
  &.gray {
    color: #ffffff;
    background: #777777;
  }
  &.yellow {
    color: #000000;
    background: #ffd331;
  }
}

.mr-10 {
  margin-right: 10px;
}

.bg-title-table th {
  background-color: #F3EBE8;
}

.loader-fade {
  position: fixed;
  background: #000;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.3;
  z-index: 99999;
}

.loader {
  width: 60px;
  height: 60px;
  position: fixed;
  top: calc(50% - 30px);
  left: calc(50% - 30px);
  z-index: 100000;
}

/*****************************************************************
TABLE FIXED HEADER
*******************************************************************/
.table-fixed-header {
  overflow: auto;
  min-height: 300px;

  & thead th {
    position: sticky;
    top: -1px;
    z-index: 10;

    border-top: #dee2e6;
    border-bottom: 2px solid #000;
  }

  & > .table > :not(:first-child) {
    border-top: none;
  }
}

.card-header {
  font-size: 1.5rem;
  font-weight: bold;
  padding: 1rem
}

.text-right {
  text-align: right;
}


/*******************************
adjust react datepicker layout
********************************/
.react-datepicker__header__dropdown.react-datepicker__header__dropdown--select {
  display: flex;
  justify-content: center;
}

.react-datepicker__navigation {
  top: 8px !important;
}

.react-datepicker__month-dropdown-container.react-datepicker__month-dropdown-container--select {
  order: 2;
}

.react-datepicker__year-dropdown-container.react-datepicker__year-dropdown-container--select {
  order: 1;
}

.react-datepicker__current-month.react-datepicker__current-month--hasYearDropdown.react-datepicker__current-month--hasMonthDropdown {
  display: none;
}