@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200&display=swap');

* {
    padding: 0;
    margin: 0;
    box-sizing: border-box
}

.container {
    min-height: 80vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.card-login {
    height: 100%;
    width: 450px;
    background-color: #fff;
    position: relative;
    box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    padding: 20px
}


.text {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center
}

.text p {
    font-size: 13px;
    color: #bac4d4;
    font-weight: 700
}

.text h3 {
    color: #ef8412;
    font-weight: bold;
}

.fa-envelope {
    position: absolute;
    left: 10px;
    cursor: pointer;
    top: 15px
}

.fa-lock {
    position: absolute;
    left: 12px;
    top: 15px;
    cursor: pointer;
    font-size: 15px
}

.input-text .fa-eye-slash {
    position: absolute;
    right: 10px;
    top: 15px;
    font-size: 15px;
    cursor: pointer
}

.fa-eye {
    position: absolute;
    right: 10px;
    top: 15px;
    font-size: 15px;
    cursor: pointer
}

.buttons {
    display: flex;
    justify-content: center;
    align-items: center
}

.buttons-confirm button{
    width: 30%;
    background-color: #ef9412;
    border: none;
    border-radius: 9px;
    cursor: pointer;
    transition: all 0.5s;
    color: #fff;
    font-size: 18px;
}

.buttons button {
    min-width: 150px;
}

.buttons button:hover {
    background-color: #ef9412
}

.forgot {
    margin-top: 30px;
    font-size: 10px;
    display: flex;
    justify-content: center;
    align-items: center
}

.forgot a {
    text-decoration: none
}

.warning {
    border: 1px solid red !important
}

.type_password {
    border: 1px solid red !important
}

.error {
    color: red;
    text-align: center;
}

.modal-footer{
    display: block !important;
}
.roles {
    padding: 20px;
}
.buttons button{
    background-color: #ef8412;
}

.btn-primary {
    background-color: #ef8412 !important;
    border-color: transparent !important;

    &:hover {
        box-shadow: 0 0 0 0.25rem rgb(239, 132, 18, 0.3)
    }
}