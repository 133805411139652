.custom-table-width {
    width: 70%;
    margin: auto;
    margin-bottom: 20px;
}

// Small devices (landscape phones, 576px and down)
@media (max-width: 576px) {
    .custom-table-width {
        width: 100%;
    }
}

// Medium devices (tablets, 768px and down)
@media (min-width: 768px) {
    .custom-table-width {
        width: 90%;
    }
}

// Large devices (desktops, 992px and down)
@media (min-width: 992px) {
    .custom-table-width {
        width: 70%;
    }
}

// Extra large devices (large desktops, 1200px and down)
@media (min-width: 1200px) {
    .custom-table-width {
        width: 60%;
    }
}

.border-color-custom {
    border-color: #ef8412 !important;
}
